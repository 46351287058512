<template>
    <b-card no-body class="card-apply-job" >
        <b-card-header class="pb-1" >
            <b-media no-body> 
                <b-media-body class=""   v-b-modal.modal-1 style="height:80px;">
                    <h6 class="mb-0">
                      {{ item.nama_kelompok }} 
                    </h6>
                    <small class="text-muted">{{ item.nama_bidang }}</small>
                </b-media-body>

            </b-media>

        </b-card-header>
        <b-card-body >
            <!-- payment  -->
            <div  >
                <div class="apply-job-package bg-light-primary rounded">
                <div class="text-primary" style="margin-top: -10px;;">
                    <small> 
                        <b-row >
                            
                                <small class="ml-1"><b>Jam kerja :</b><br> {{ item.jam_masuk }} - {{ item. jam_pulang}}, <br> - Durasi kerja <b>{{ item.durasi_kerja}} Jam</b></small>
                            
                        </b-row>
                        <b-row>
                          
                                <!-- <b-avatar rounded="" size="25">
                                    <feather-icon icon="UserIcon" size="20" />
                                </b-avatar> -->
                                <small class="ml-1"> <b>User Aktf : </b>  <br> <b> {{ item.user_aktif }}</b> User Aktif Presensi</small>
                            
                        </b-row>
                        <!-- <b-row class="mt-1">
                            <b-col md="12">
                                <b-avatar rounded="" size="25">
                                    <feather-icon icon="MapPinIcon" size="20" />
                                </b-avatar>
                                <b class="ml-1">{{ item.kordinat_aktif }} </b>
                            </b-col>
                        </b-row> -->

                        <b>keterangan</b> <br>
                        <small v-if="item.keterangan == null"> -</small><br>
                        <small v-if="item.keterangan != null">{{ item.keterangan }}</small><br>
                    </small>
                </div>
            </div>
            </div>
           

            <!--/ payment  -->
            <router-link :to="'/laporan_perkelompok_detail/'">
                <b-button   class="mb-1" block variant="primary" @click="id_kelompok(item.id_kelompok)">
                    <feather-icon icon="GridIcon" size="20" /> <span
                        class="ml-1 mb-1"><b> </b>  Detail Kelompok </span>
                </b-button> 
            </router-link>  
              <a :href="baseUrl +'download/rekap_kelompok/file_data/'+item.id_kelompok+'/'+tgl+'/'+isAuthenticated" target="_blank">
                <b-button   class="mb-1" block variant="warning" > 
                    <feather-icon icon="DownloadIcon" size="20" /> <span
                        class="ml-1 mb-1"><b> </b>  Download  </span>
                </b-button> 
            </a>
        </b-card-body>
    </b-card>
</template>

<script>
import Base from '@/config/Mixins_base'
    import {
        mapMutations,
        mapState
    } from 'vuex'

    import {
        BCol,
        BRow,
        BCard,
        BCardHeader,
        BMedia,
        BMediaAside,
        BAvatar,
        BMediaBody,
        BBadge,
        BCardText,
        BCardBody,
        BButton,
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'

    export default {
        components: {
            BCard,
            BCol,
            BRow,
            BButton,
            BBadge,
            BCardHeader,
            BMedia,
            BMediaAside,
            BAvatar,
            BMediaBody,
            BCardText,
            BCardBody,
        },
        directives: {
            Ripple,
        },
        props: {
            item: {},
            tgl : {}
        },
        mixins :[Base],
        mounted(){
            this.cek_token();
        },
        computed: {
            ...mapState({
                IdKelompok: state => state.laporan.IdKelompok,
                Tanggal: state => state.laporan.Tanggal,
                RsKelompok: state => state.laporan.RsKelompok,
            })
        },
        emits: ['statusKelompok', 'detail', 'loadEdit'],
        methods: {
            ...mapMutations({
                SetIdKelompok: 'SetIdKelompok', 
                SetTanggal : 'SetTanggal'
            }),
            status_kelompok(value) {
                this.$emit("statusKelompok", value)
            },
            detail(value) {
                this.$emit("detail", value)
            },
            loadEdit(value) {
                this.$emit("detail", value)
            },
            id_kelompok(val){
                this.SetIdKelompok(val);
                this.SetTanggal(this.tgl)
            }, 
        }
    }
</script>