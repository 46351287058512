<template>
    <div> 

        <b-row>
            <b-col md="8">
                <statistik :statistik=statistik></statistik>
            </b-col>
            <b-col md="4">
                <card_search :rsTahun=rsTahun :tahun="tahun" :bulan="bulan" @loadData=loadData></card_search>
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col md="5">
                <h3>
                    <feather-icon icon="CalendarIcon" size="40" /> Rekap {{  tanggal }}</h3>
            </b-col>
            <b-col md="3"> 
                <b-form-group> 
                    <v-select  v-model="id_bidang" @input="cariData()"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_bidang" :options="bidang" />
                </b-form-group>
            </b-col>
            <b-col md="3">
                <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text style="float: right;">
                        <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input placeholder="Search" v-model="cari" @input="cariData()" />
                </b-input-group>
            </b-col>
            <b-col md="1">
                <b-button block variant="warning" size="sm" @click="cleadSearch()">
                    <feather-icon icon="RefreshCwIcon" size="18" />
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="3" v-for="item in items.rs" v-bind:key="item.id_kelompok">
                <kelompok :item=item :tgl=tgl></kelompok>
            </b-col>
        </b-row>

        <b-row>
            <b-col md="6">
                Total Row {{ items.lenght}}
            </b-col>
            <b-col md="6">
                <b-pagination v-model="currentPage" hide-goto-end-buttons :per-page='4' :total-rows="items.lenght"
                    align="right" @input="pageNext(currentPage)" />
            </b-col>
        </b-row>

    </div>
</template>

<script>
    import axios from '@/config/Axios'
    import Base from '@/config/Mixins_base'
    import {
        mapMutations,
        mapState
    } from 'vuex'
    import {
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BInputGroupAppend,
        BFormGroup,
        BFormTextarea,
        BPagination
    } from 'bootstrap-vue'

    import {
        BRow,
        BCol,
        BButton,
        BFormSelect,
    } from 'bootstrap-vue'
    import Kelompok from './Kelompok.vue'
    import card_search from './cardSearch.vue'
    import statistik from './statistik.vue'
    import vSelect from 'vue-select'
    export default {
        components: {
            BRow,
            BCol,
            BFormSelect,
            BButton,
            statistik,
            card_search,
            BFormTextarea,
            BInputGroup,
            BFormGroup,
            BFormInput,
            BInputGroupPrepend,
            BInputGroupAppend,
            Kelompok,
            BPagination,
            vSelect
        },
        computed: {
            ...mapState({
                RsStatistik: state => state.laporan.RsStatistik,
                RsDate: state => state.laporan.RsDate,
                RsKelompok: state => state.laporan.RsKelompok,
            })
        },
        mixins: [Base],
        data() {
            return {
                items: [],
                statistik: '',
                currentPage: 1,
                perPage: 3,
                rows: 0,
                cari: '',
                offset: 0,
                tgl: '',
                tahun: '',
                bulan: '',
                rsTahun: [],
                tanggal: "",
                bidang: [],
                id_bidang : {}

            }
        },
        mounted() {
            this.cek_token();
            this.setDatenow(),
                this.load_statistik();
            this.getTahun();
            this.load_data();
            this.tgl_now();
            this.load_bidang();
        },
        methods: {
            ...mapMutations({
                SetRsstatistik: 'SetRsstatistik',
                SetRsDate: 'SetRsDate',
                SetRsKelompok: 'SetRsKelompok'
            }),
            async load_bidang() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/laporan/kelompok/load_bidang',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.bidang = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            tgl_now() {
                const nm_bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus',
                    'September', 'Oktober', 'November', 'Desember'
                ]
                const ddk = new Date(this.tgl)
                this.tanggal = 'Bulan ' + nm_bulan[ddk.getMonth()] + ', ' + ddk.getFullYear();
            },

            setDatenow() {
                const date = new Date();
                this.tahun = date.getFullYear();
                this.bulan = String(date.getMonth() + 1).padStart(2, '0');
                this.tgl = this.tahun + '-' + this.bulan;
            },
            loadData(tahun, bulan) {

                const self = this;
                self.tgl = tahun + '-' + bulan;
                self.load_statistik();
                self.load_data();
                self.bulan = bulan;
                self.tahun = tahun;
                this.tgl_now();
            },
            async getTahun() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/laporan/kelompok/tahun',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.rsTahun = response.data.result;
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async load_statistik() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/laporan/kelompok/statistik',
                        data: {
                            cari: self.cari,
                            offset: self.offset,
                            tgl: self.tgl
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.statistik = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            pageNext(currentPage) {
                const self = this;
                self.offset = currentPage;
                self.load_data();
            },
            cleadSearch() {
                const self = this;
                self.cari = '';
                self.load_data();
            },
            cariData() {
                const self = this;
                self.load_data();
            },
            async load_data() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/laporan/kelompok/load_data',
                        data: {
                            cari: self.cari,
                            offset: self.offset,
                            tgl: self.tgl,
                            id_bidang : self.id_bidang.id_bidang
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.items = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
        }

    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>